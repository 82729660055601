import React, { useCallback } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
// import logo from './logo.svg';
import './App.css';
// import particlesOptions from "./particles.json";
import particlesOptions from "./particles_blue_lines_hexagon.json";

// StylesBaseline Breaks canvas
// All The Frames appear to break the Window 'Port'

import LinkContainer from './components/LinkContainer'

function App() {
    
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])


    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
            <header className="App-header">
                <div id='repulse-div'>
                   <LinkContainer/>
                    </div> 
            </header>
        </div>
    );
}

export default App;

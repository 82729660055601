import React from 'react'; // FC, 
import { FrameHexagon, FrameLines, Text, Button,} from '@arwes/core';
import {ThemeProvider} from "@emotion/react"
import {createThemePaletteBasic, createTheme, } from '@arwes/design'; // createThemePaletteElevation

// https://github.com/arwes/arwes/blob/next/packages/core/src/ArwesThemeProvider/ArwesThemeProvider.component.tsx


const LinkContainer = (props) => {
    // https://playground.arwes.dev/design/createTheme/palette
  
    const primaryColor = '#48f'  //'#00f0f8' 
    const textColor = '#fed'
    const theme = createTheme({
      
        palette: {
            tonalOffset: 0.1,
            primary: { main: primaryColor }, //'#00f0f8' 
            secondary: { main: '#e6ff44' }, // split complementarty #e6ff44 # original F8F800
            // text: { main: '#2CFFFF' },
            neutral: { main: '#021114' },
            success: { main: '#8f4' }, 
            error: { main: '#f48' }, 
            elevationOffset: 0.025,
            text: {
                root: textColor, //'#00f0f8'
                headings: textColor,//'#00f0f8'
                link: textColor,
                linkHover: textColor
            },
          // Custom theme palettes.
            mfPalette: createThemePaletteBasic({ main: '#48f' }, 0.1),
            twitchPalette: createThemePaletteBasic({ main: '#9146FF' }, 0.1),
            linkedinPalette: createThemePaletteBasic({ main: '#00a0dc' }, 0.1),
            twitterPalette: createThemePaletteBasic({ main: '#1DA1F2' }, 0.1),
// https://www.colorhexa.com/4488ff
// triadic #8f4 = green, blue = #48f, red=#f48
 // tetradic and complementary: orange = #fb4 blue = #48f,
 // split complementary #ff5e44 yellow
//  I 'inspected' html to retrieve color of (I assume arwes) background
    //   yourOwnBasicPalette: createThemePaletteBasic({ main: '#00506b' }, 0.1),
    //   yourOwnElevationPalette: createThemePaletteElevation('#00151d', 0.025)
    // using linkedin brigther accent color 00a0dc ; not primary 0077b5
    // https://brand.linkedin.com/content/brand/global/en_us/index/visual-identity/color-palettes

        }
      });
   
 // creating my own theme changes the 'Text' to black
    return (

       
        <ThemeProvider theme={theme}>
        
        <FrameLines    style={{backgroundColor: 'rgba(2,11,14,0.85)', maxWidth: 800,  }}  >
            {/* <Card  style={{backgroundColor: 'rgba(2,11,14,0.85)', maxWidth: 800 }}                 
                options={
                    <Button palette='secondary'>
                    <Text>Learn More</Text>
                    </Button>
                }     > */}
                                                   
            <Text style={{ paddingLeft: 24,  paddingRight: 24,}} >
                Mastro Finance
            </Text>
            <br></br>
            <br></br>
            <Text style={{ paddingLeft: 24,  paddingRight: 24,}} >
                Financial News Network
            </Text>
            <br></br>
            <Text style={{ paddingLeft: 24,  paddingRight: 24,}} >
                Powered by Artificial Intelligence
            </Text>

            <br></br>
            <br></br>
            <Button FrameComponent={FrameHexagon} palette='error'
                rel="noopener noreferrer"  href="https://www.youtube.com/channel/UC0ZqBIklNKkIEeaYgKYt7MA"
                target="_blank" 
                onClick={() => {
                    window.open(
                        "https://www.youtube.com/channel/UC0ZqBIklNKkIEeaYgKYt7MA",
                      '_blank' // <- opens the link in a new tab or window.
                    );
                  }}>
                <Text>Youtube Live</Text>
            </Button>
            <br></br>
            <br></br>
            <Button FrameComponent={FrameHexagon} palette='twitchPalette'
                rel="noopener noreferrer"  href="https://www.twitch.tv/mastrofinance"
                target="_blank" 
                onClick={() => {
                    window.open(
                        "https://www.twitch.tv/mastrofinance",
                      '_blank' // <- opens the link in a new tab or window.
                    );
                  }}>
                <Text a='h1'>Twitch Live</Text>
            </Button>
            <br></br>
            <br></br>
            <Button FrameComponent={FrameHexagon} palette='linkedinPalette'
                rel="noopener noreferrer"  href="https://www.linkedin.com/company/mastro-finance"
                target="_blank" 
                onClick={() => {
                    window.open(
                        "https://www.linkedin.com/company/mastro-finance",
                      '_blank' // <- opens the link in a new tab or window.
                    );
                  }}>
                <Text>LinkedIn</Text>
            </Button>
            <br></br>
            <br></br>
            <Button FrameComponent={FrameHexagon} palette='twitterPalette'
                rel="noopener noreferrer"  href="https://twitter.com/mastrofinance"
                target="_blank" 
                onClick={() => {
                    window.open(
                        "https://twitter.com/mastrofinance",
                      '_blank' // <- opens the link in a new tab or window.
                    );
                  }}>
                <Text>Twitter</Text>
            </Button>
        </FrameLines>

        {/* <br></br>
        <br></br>

        <FrameLines    style={{backgroundColor: 'rgba(2,11,14,0.85)', maxWidth: 800,  }}  >
                      
            <Text style={{ paddingLeft: 24,  paddingRight: 24,}} >
                Quantitative Finance Research
            </Text>
            
            
        </FrameLines> */}
        
        </ThemeProvider>
        
    )
}


export default LinkContainer

// https://playground.arwes.dev/core/StylesBaseline/basic // has Theme for text
// palette: {
//     tonalOffset: 0.1,
//     primary: { main: '#00f0f8' },
//     secondary: { main: '#F8F800' },
//     text: { main: '#2CFFFF' },
//     neutral: { main: '#021114' },
//     elevationOffset: 0.025,
//     text: {
//         root: '#35efaa',
//         headings: '#40ffce',
//         link: '#c466dc',
//         linkHover: '#d491fa'
//     },
  // Custom theme palettes.
    // mfPalette: createThemePaletteBasic({ main: '#0400ff' }, 0.1),
//   yourOwnBasicPalette: createThemePaletteBasic({ main: '#00506b' }, 0.1),
//   yourOwnElevationPalette: createThemePaletteElevation('#00151d', 0.025)
// }
// ABC3CC pale blue
// 00506b dull-blue https://www.colorhexa.com/00506b
// 0400ff bright-blue
// FFC000 yellow
// {
    // primary: { main: '#00f8f8' },
    // secondary: { main: '#F8F800' },
    // text: { main: '#2CFFFF' },
    // neutral: { main: '#021114' },
//   }
//  palette={{main:"#00f8f8",
//         dark1:"#05c6c1",
//         dark2:"#0b8481",
//         dark3:"#15333c",
//         light1:"#3efbfb",
//         light2:"#7efcf6",
//         light3: "#befcfe" 

// animator={{ animate: false }}
//  https://arwes.dev/develop/core/

// // <ArwesThemeProvider  >
  //  <StylesBaseline styles={{ body: { fontFamily: ROOT_FONT_FAMILY },  }} />
 //      <AnimatorGeneralProvider animator={generalAnimator}>
 //         //<MainInterface />
 //     </AnimatorGeneralProvider>
//</ArwesThemeProvider >